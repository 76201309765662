<template>
  <div class="r-simple-card">
    <div
      v-if="iconName || title || subtitle"
      class="r-simple-card__header"
      :class="{ noToggler }"
      @click="toggleHandler()"
    >
      <r-icon
        v-if="iconName"
        :name="iconName"
        :size="20"
      />
      <div class="r-simple-card__title">
        <r-title
          v-if="title"
          type="subtitle-2"
        >
          {{ title }}
        </r-title>
        <r-text
          v-if="subtitle"
          type="caption"
          :color-type="subtitleColorType"
        >
          {{ subtitle }}
        </r-text>
      </div>
      <div
        v-if="!noToggler"
        class="r-simple-card__toggler"
      >
        <r-icon
          name="chevron-down"
          :rotate="isOpen ? 180 : 0"
          :size="20"
        />
      </div>
    </div>
    <transition
      name="fadeHeight"
      mode="out-in"
    >
      <div
        v-if="isOpen"
        class="r-simple-card__content"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    noToggler: {
      type: Boolean,
      default: false
    },
    defaultClosed: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    subtitleColorType: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isOpen: this.noToggler || !this.defaultClosed
    }
  },
  watch: {
    defaultClosed() {
      this.isOpen = this.noToggler || !this.defaultClosed
      this.$emit('toggle', !this.isOpen)
    }
  },
  methods: {
    toggleHandler() {
      if (this.noToggler) return
      this.$emit('toggle', !this.isOpen)

      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="scss">
.r-simple-card {
  position: relative;
  padding: 8px;
  background-color: $bg-containers-white !important;
  border-radius: $border-radius;
  @include grid-column;

  &__header {
    width: 100%;
    cursor: pointer;
    display: grid;
    align-self: start;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
    user-select: none;
    grid-template-columns: auto 1fr auto;

    .r-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block !important;
    }

    &.noToggler {
      cursor: default;
      justify-content: start;
    }
  }

  &__toggler {
    justify-self: end;
  }

  &__title {
    display: grid;
    align-items: center;
  }

  &__content {
    width: 100%;
    overflow: hidden;
  }
}
</style>
